import { API } from "./axioshelper"

export async function deleteToken() {
  return await API.request({
    method: "get",
    url: "logout",
  })
}

export default deleteToken

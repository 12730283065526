import { toast } from "react-toastify"

export interface ToastAction {
  type: string
}

export function toggleErrorMessageAction() {
  return (dispatch: (arg0: { type: string }) => void): void => {
    dispatch({ type: "TOGGLE_ERROR_MESSAGE" })
    toast.error("Une erreur s'est produite veuillez réessayer plus tard", {
      position: "bottom-center",
      autoClose: 6000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

export function toggleSuccessMessageAction() {
  return (dispatch: (arg0: { type: string }) => void): void => {
    dispatch({ type: "TOGGLE_SUCCESS_MESSAGE" })
    toast.success("Votre action a bien été prise en compte", {
      position: "bottom-center",
      autoClose: 6000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
}

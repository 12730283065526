import React, { useEffect, useState } from "react"
import { IonIcon, IonSpinner, isPlatform } from "@ionic/react"
import { type FormikType } from "types/utils"
import { useLocation } from "react-router-dom"
import { addFile } from "api/request"

import { useHistory } from "react-router"
import { toast } from "react-toastify"
import Compressor from "compressorjs"
import fileIcon from "../theme/images/icon_file.svg"
import check from "../theme/images/checkmark-outline.svg"
import Button from "./Button"

interface InputMessengerType {
  formik?: FormikType<any> | undefined
  deleteFile: any
  fileList: any
  setfileList: any
  isFileAllowed: boolean
  isFileRequired: boolean
  formsend: boolean
  isFileLoading: boolean
  setIsFileLoading: Function
  isDeleteFileLoading: boolean
  isError: boolean
  fileRef: any
}

const defaultProps = {
  formik: undefined,
}

export function InputTextMessenger(props: InputMessengerType) {
  const {
    formik,
    deleteFile,
    fileList,
    setfileList,
    isFileAllowed,
    isFileRequired,
    formsend,
    isFileLoading,
    setIsFileLoading,
    isDeleteFileLoading,
    isError,
    fileRef,
  } = props
  const location = useLocation()
  const router = useHistory()

  useEffect(() => {
    if (location.pathname === "/nouvelle-demande") {
      if (fileList.length > 0) {
        setfileList([])
        formik?.setFieldValue("file", [])
      }
    }
  }, [location.pathname, isError])

  const handleCompressedUpload = (file: any) => {
    if (
      file.type.toLowerCase() !== "image/png" &&
      file.type.toLowerCase() !== "image/jpg" &&
      file.type.toLowerCase() !== "image/jpeg"
    ) {
      // C'est un fichier .PDF, .DOC ou .DOCX
      return file
    }
    return new Compressor(file, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      success(compressedResult) {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        return compressedResult
      },
      error() {
        toast.error("Il y a eu une erreur. Vérifiez que le fichier est bien accepté avant de réessayer.")
      },
    })
  }

  const [restCount, setRestCount] = useState<string>("255 caractères restants.")
  const handleMessageLen = (e: any) => {
    const count = 255 - parseInt(e.target.value.length, 10)
    if (count <= 0) {
      setRestCount(`Vous avez atteint la limite maximum de caractères autorisée (${count}/255 caractères).`)
    } else {
      setRestCount(`${count} caractères restants.`)
    }
  }

  function hideMenuBarOnFocusHandler(keyboardState: string) {
    const menus = document.querySelectorAll(".mobile_tab-nav-container")
    if (!isPlatform("capacitor")) {
      menus.forEach((menu) => {
        const hideClass = menu?.classList.contains("hide")
        if (keyboardState === "hide") {
          if (!hideClass) {
            menu.classList.add("hide")
          }
        } else if (keyboardState === "show") {
          if (hideClass) {
            menu.classList.remove("hide")
          }
        }
      })
    }
  }

  const fileChangeEvent = async (e: any) => {
    const acceptedTypes = ["png", "jpeg", "jpg", "heic", "pdf", "doc", "docx"]

    setIsFileLoading(true)

    if ((e.target.files[0]?.size ?? 0) / 1024 > 2000) {
      toast.error("Le fichier choisi est trop volumineux (max. 2Mo)")
      setIsFileLoading(false)
      return false
    }

    const fileExtension = e.target.files[0]?.name.split(".").at(-1).toLowerCase()

    if (e.target.files[0] && !acceptedTypes.includes(fileExtension)) {
      toast.error("Ce type de fichier n'est pas accepté. Veuillez réessayer.")
      setIsFileLoading(false)
      return false
    }

    try {
      if (e.target.files[0]) {
        const compressedFile = await handleCompressedUpload(e.target.files[0])
        const names = fileList.map((item: any) => (item.result ? item.result.name : item.name))
        if (names.includes(e.target.files[0].name)) {
          throw Error("Il semble que vous ayez déjà transmis ce fichier.")
        }
        const addedFile = await addFile(
          compressedFile.file ? compressedFile.file : compressedFile,
          formik?.values.requestId
        )
        setfileList([...fileList, compressedFile])
        formik?.setFieldValue("file", [...formik.values.file, addedFile])
      } else {
        setIsFileLoading(false)
      }

      return true
    } catch (error: any) {
      if (error.response) {
        console.error(`Error status ${error.response.status}: ${error.response.statusText}`)
        if (error.response.data?.error === "The file is already cached.") {
          toast.error("Vous avez déjà ajouté ce fichier.")
        } else {
          toast.error(error.response?.data?.error)
        }
        if (error.response.data?.error !== "The file is already cached.") {
          router.push("/demandes")
        }
      } else if (error.message) {
        toast.error(error.message)
      } else {
        toast.error("Une erreur est survenue lors du traitement du fichier. Veuillez réessayer.")
      }
      return false
    } finally {
      setIsFileLoading(false)
    }
  }

  return (
    <>
      <label className="new-message_title" htmlFor="message-input">
        Rédigez votre <strong>message</strong>
        <span className="new-message_subtitle">(Obligatoire)</span>
        <textarea
          id="message-input"
          title="Rédigez votre message"
          name="message"
          className="new-message_input-message"
          placeholder="255 caractères max."
          onFocus={() => {
            hideMenuBarOnFocusHandler("hide")
          }}
          onBlur={(e) => {
            hideMenuBarOnFocusHandler("show")
            formik?.handleBlur(e)
          }}
          onChange={(e) => {
            formik?.handleChange(e)
            handleMessageLen(e)
          }}
          value={formik?.values.message}
        />
      </label>
      {formik?.touched.message && formik?.errors.message && (
        <p className="input-error" id="messageAbout-input-error">
          {formik.errors.message as any}
        </p>
      )}{" "}
      {!formik?.errors.message && !formik?.errors.message && <p className="new-message_count">{restCount}</p>}
      {isFileAllowed ? (
        <>
          <h2 className="new-message_title">
            Vos <strong>pièces jointes</strong> (3 maximum){" "}
            {isFileRequired ? (
              <span className="new-message_subtitle">Obligatoire</span>
            ) : (
              <span className="new-message_subtitle">Facultatif</span>
            )}
          </h2>
          {fileList?.map((file: any, i: number) => (
            <div className="new-message_file-title" key={file?.name}>
              <p>{file?.file?.name || file?.name}</p>
              {file && (file?.file?.name || file?.name) && (
                <>
                  <IonIcon icon={check} className="check-icon" aria-hidden="true" />
                  <button
                    type="button"
                    className="new-message_delete-file"
                    onClick={(e) => {
                      deleteFile(i, e)
                    }}
                    aria-label={`Bouton de suppression du fichier ${file.file?.name || file.name}`}
                    data-filename={file.file?.name || file.name}
                  >
                    {isDeleteFileLoading ? (
                      <IonSpinner color="dark" />
                    ) : (
                      <>
                        Supprimer <span className="sr-only">le fichier {file.file?.name || file.name}</span>
                      </>
                    )}
                  </button>
                </>
              )}
            </div>
          ))}
          <label // replace with button
            htmlFor="file-input"
            className={`new-message_add-file ${fileList.length > 2 ? "disabled" : ""}`}
            tabIndex={0}
            onKeyUp={(e: any) => {
              if (e.code === "Space") {
                fileRef.current.click()
              }
            }}
          >
            <input
              id="file-input"
              type="file"
              accept="image/png, image/jpeg, image/jpg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .png, .jpeg, .jpg, .pdf, .doc, .docx, .heic"
              className="new-message_add-file_input"
              placeholder="Joindre un fichier"
              onChange={fileChangeEvent}
              ref={fileRef}
              required={isFileRequired}
              title="Joindre un fichier"
              disabled={fileList.length > 2}
            />
            Joindre un fichier
            {isFileLoading ? <IonSpinner color="dark" /> : <IonIcon icon={fileIcon} aria-hidden="true" />}
          </label>
          {isFileRequired ? (
            <p className="new-message_subtitle new-message_subtitle--info">
              {formik?.values.file.length >= 1 ? null : "Vous devez ajouter au moins un fichier."}
            </p>
          ) : null}
        </>
      ) : null}
      <div>
        <Button
          className="new-message_submit"
          type="submit"
          disabled={!formik?.isValid || formsend || isFileLoading || (isFileRequired && formik.values.file?.length < 1)}
          aria-label="Bouton d'envoi du message"
        >
          Envoyer mon message
          {formsend ? <IonSpinner name="circles" /> : ""}
        </Button>
      </div>
    </>
  )
}

InputTextMessenger.defaultProps = defaultProps

export default InputTextMessenger

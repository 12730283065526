import React from "react"
import { Link } from "react-router-dom"
import "./PaymentValidation.scss"
import AuthLayout from "layouts/AuthLayout"
import Footer from "components/Footer"
import { useSelector } from "react-redux"
import { type RootState } from "../stores/reducers"

function PaymentValidation(props: any) {
  const dark = useSelector((state: RootState) => state.theme.dark)
  // eslint-disable-next-line react/destructuring-assignment
  const paymentDatas = props.history.location.state?.clientAnswer

  return (
    <AuthLayout>
      <section className="payment-confirm-container">
        {paymentDatas ? (
          <div className="success-container">
            <h1>Votre paiement a bien été validé.</h1>
            <h5>Votre paiement a été pris en compte par nos services.</h5>
            <h5>
              Il sera visible sous
              <strong>48h à 72h</strong> dans votre Espace Locataire.
            </h5>
            <div className="resume-container">
              <h2 className={`${dark ? "dark" : ""}`}>Details du paiement</h2>
              <div className={`resume-details-container ${dark ? "dark" : ""}`}>
                <div>Montant :</div>
                <div>
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: paymentDatas.orderDetails.orderCurrency,
                  }).format(paymentDatas.orderDetails.orderTotalAmount / 100)}
                </div>
              </div>
              <div className={`resume-details-container ${dark ? "dark" : ""}`}>
                <div>Date :</div>
                <div>le {new Date(paymentDatas.serverDate).toLocaleString().split(" ")[0]}</div>
              </div>
              <div className={`resume-details-container--row ${dark ? "dark" : ""}`}>
                <div className="email">Votre e-mail :</div>
                <div className="email">{paymentDatas.customer.email}</div>
              </div>
            </div>
            <Link to="/dashboard" replace className="back-link" title="Retourner à l'Espace Personnel">
              Retour à votre Espace Personnel
            </Link>
          </div>
        ) : null}
      </section>
      <Footer />
    </AuthLayout>
  )
}

export default PaymentValidation

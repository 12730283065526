import { IonIcon, IonItem, IonLabel, IonList } from "@ionic/react"
import React from "react"
import "./PasswordSecurityHelper.scss"
import { useSelector } from "react-redux"
import { type RootState } from "stores/reducers"
import { checkLength, checkLetterNumber, checkChar } from "../utils/passwordValidation"
import checkmarkCircle from "../theme/images/checkmark-circle.svg"
import checkmarkError from "../theme/images/close-sharp.svg"

interface PasswordSecurityHelperProps {
  password: string
}

export function PasswordSecurityHelper({ password }: PasswordSecurityHelperProps) {
  const validLength = checkLength(password)
  const validChar = checkLetterNumber(password)
  const validSpeChar = checkChar(password)
  const dark = useSelector((state: RootState) => state.theme.dark)

  return (
    <IonList lines="none" className="password-security-helper">
      <IonItem className={`${dark ? "dark" : ""}`}>
        <IonIcon
          className={validLength ? "valid" : "invalid"}
          icon={validLength ? checkmarkCircle : checkmarkError}
          aria-hidden="true"
        />
        <IonLabel>Doit contenir au moins 8 caractères</IonLabel>
      </IonItem>
      <IonItem className={`${dark ? "dark" : ""}`}>
        <IonIcon
          className={validChar ? "valid" : "invalid"}
          icon={validChar ? checkmarkCircle : checkmarkError}
          aria-hidden="true"
        />
        <IonLabel>Doit contenir au moins une minuscule, une majuscule et un chiffre</IonLabel>
      </IonItem>
      <IonItem className={`${dark ? "dark" : ""}`}>
        <IonIcon
          className={validSpeChar ? "valid" : "invalid"}
          icon={validSpeChar ? checkmarkCircle : checkmarkError}
          aria-hidden="true"
        />
        <IonLabel>Doit contenir un caractère spécial (! @ # $ % &)</IonLabel>
      </IonItem>
    </IonList>
  )
}

export default PasswordSecurityHelper

import { API } from "./axioshelper"
import { type ProfileInfosChanges, type Consent, type BillingSummary } from "../types/common"

export async function fetchBillingSummary() {
  return API.request<BillingSummary>({
    method: "get",
    url: "profile/billingSummary",
  }).then((response) => response.data)
}

export async function patchProfileInfos(datas: {
  landlinePhone: string | null
  mobilePhone: string | null
  password: string | null
  consents: Consent[]
}) {
  return API.request<ProfileInfosChanges>({
    method: "patch",
    url: "profile",
    data: datas,
  })
}

export async function fetchConsent() {
  return API.request<Consent[]>({
    method: "get",
    url: "profile/consentements",
  }).then((response) => response.data)
}

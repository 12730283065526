import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { API } from "./axioshelper"
import { toggleErrorMessageAction } from "../stores/actions/toastMessageActions"
import { DemandType, Qualifiers } from "../types/common"

export async function fetchRequests(indexPage: number) {
  return await API.request<DemandType[]>({
    method: "get",
    url: `request/${indexPage}`,
  })
    .then((response) => response.data)
    .catch((err) => {
      console.error(err)
      toast.error("Une erreur est survenue. Merci de réessayer plus tard.")
    })
}

export async function fetchRequestsDetails(idRequest: string) {
  return await API.request({
    method: "get",
    url: `request/details/${idRequest}`,
  }).then((response) => response.data)
}

export async function getRequestId() {
  return await API.request({
    method: "get",
    url: "request/getRequestId",
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response) {
        console.error(`Error status ${error.response.status}: ${error.response.statusText}`)
        toast.error(error.response.data.error)
      } else {
        toast.error("Nous ne pouvons traiter votre demande pour le moment. Réessayez plus tard.")
      }
      useHistory().push("/demandes")
    })
}

export async function addFile(file: any, uuid: string) {
  const data = new FormData()
  data.append("file", file)
  data.append("uuid", uuid)

  return await API.request({
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    url: "request/addFile",
    data,
  }).then((response) => response.data)
}

export async function deleteFileRequest(fileName: string) {
  const data = new FormData()
  data.append("name", fileName)

  return await API.request({
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    url: "request/deleteFile",
    data,
  })
}

export async function postRequest(
  object: string,
  idBien: string,
  category: string,
  message: string,
  filelist: any,
  commonPart: boolean,
  startDate?: string,
  endDate?: string,
  insurer?: string
) {
  // To upload files with axios, we need a FormData
  const data = new FormData()

  data.append("object", object)
  data.append("idBien", idBien)
  data.append("category", category)
  data.append("message", message)

  if (filelist.length >= 1) {
    data.append("hasAttachment", "true")
  } else {
    data.append("hasAttachment", "false")
  }

  if (insurer && startDate && endDate) {
    data.append("startDate", startDate)
    data.append("endDate", endDate)
    data.append("insurer", insurer)
  }

  data.append("commonPart", commonPart.toString())
  // eslint-disable-next-line no-restricted-syntax
  for (const file of filelist) {
    data.append("file[]", file)
  }
  return await API.request({
    method: "post",
    url: "request",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  })
}

export async function fetchRequestQualifiers() {
  return await API.request<Qualifiers[]>({
    method: "get",
    url: "request/qualifiers",
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response) {
        console.error(`Error status ${error.response.status}: ${error.response.statusText}`)
        toast.error(error.response.data.error)
      } else {
        useDispatch()<any>(toggleErrorMessageAction())
      }
      useHistory().push("/demandes")
    })
}

export async function fetchRequestEnabled() {
  return await API.request({
    method: "get",
    url: "request/enabled",
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response) {
        console.error(`Error status ${error.response.status}: ${error.response.statusText}`)
        toast.error(error.response.data.error)
      } else {
        useDispatch()<any>(toggleErrorMessageAction())
      }
    })
}

import { useRef } from "react"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { type FormikType } from "../types/utils"
import "./Preference.scss"

interface PreferenceType {
  updateFieldChanged: any
  formik: FormikType<any>
}

function Preference({ updateFieldChanged, formik }: PreferenceType) {
  const switchRef: any = useRef(new WeakMap())

  if (formik.values.consents.length === 0) {
    return null
  }

  return (
    <div className="container_preference" title="Mes préférences">
      <div className="container_preference">
        <h2 className="preference_title">Mes préférences</h2>
        <div className="myprofile_table-wrapper card">
          {" "}
          <fieldset className="preference_fieldset">
            <legend className="sr-only">Consentement et gestion des préférences</legend>
            <table role="presentation" className="myprofile_table">
              <tbody>
                {formik.values.consents?.map((consent: any, i: any) => (
                  <tr key={consent.consentType}>
                    <td onClick={() => switchRef.current.get(consent).click()} tabIndex={0}>
                      {consent?.consentTypeLabel || <Skeleton />}
                    </td>
                    <td>
                      <label className="switch" htmlFor={`consents${i}`} tabIndex={0}>
                        <div className="sr-only">{consent.consentTypeLabel}</div>

                        <input
                          ref={(el) => switchRef.current.set(consent, el)}
                          id={`consents${i}`}
                          name="consents"
                          type="checkbox"
                          checked={formik.values.consents[i].consentGiven}
                          onChange={updateFieldChanged(i)}
                        />
                        <div className="slider" />
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>{" "}
          </fieldset>
        </div>
      </div>
    </div>
  )
}

export default Preference

import React, { useEffect, useMemo, useRef, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useLocation } from "react-router-dom"
import { postRequest, deleteFileRequest } from "api/request"
import { useDispatch } from "react-redux"
import { Qualifiers, type QualifiersType } from "types/common"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import Input from "components/Input"
import { assurances } from "utils/functions"
import moment from "moment"
import { useQueryClient } from "@tanstack/react-query"
import { toggleErrorMessageAction, toggleSuccessMessageAction } from "../stores/actions/toastMessageActions"
import Select from "./Select"
import { useHomeDetails } from "../hooks/myHome"
import { InputTextMessenger } from "./InputTextMessenger"
import { useRequestQualifiers } from "../hooks/request"

function NewRequestForm({ requestBiens, requestId }: { requestBiens: string[]; requestId: string }) {
  const router = useHistory()
  const location = useLocation()
  const [fileList, setfileList] = useState<any>([])
  const [formsend, setFormsend] = useState<boolean>(false)
  const [closeRequest, setCloseRequest] = useState<boolean>(false)
  const [isFileAllowed, setIsFileAllowed] = useState<boolean>(false)
  const [isFileRequired, setIsFileRequired] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false)
  const [isDeleteFileLoading, setIsDeleteFileLoading] = useState<boolean>(false)
  const { data: requestQualifiers, isError: qualifiersError } = useRequestQualifiers()
  const fileRef: any = useRef()

  if (closeRequest) {
    useQueryClient().invalidateQueries({ queryKey: ["request_id"] }) // TODO to optimise
    useQueryClient().invalidateQueries({ queryKey: ["requests"] })
    setCloseRequest(false)

    router.push("/demandes")
  }

  useEffect(() => {
    if (qualifiersError) {
      console.warn("Qualifiers api not working properly.")
      dispatch<any>(toggleErrorMessageAction())
      router.push("/demandes")
    }
  }, [qualifiersError])

  const validationSchema = Yup.object().shape({
    messageObject: Yup.string().required("Ce champ est obligatoire, veuillez selectionner l'objet de votre demande."),
    idBien: Yup.string().required(
      "Ce champ est obligatoire, veuillez selectionner le bien concerné par votre demande."
    ),
    messageAbout: Yup.string().required("Ce champ est obligatoire, veuillez préciser votre demande."),
    commonPart: Yup.string().required("Ce champ est obligatoire, veuillez préciser votre demande"),
    message: Yup.string()
      .required("Ce champ est obligatoire, veuillez préciser votre demande")
      .max(255, "Le message doit contenir moins de 255 caractères."),
    file: Yup.array(),
    insurer: Yup.string().when(["messageObject", "messageAbout"], {
      is: (messageObject: string, messageAbout: string) => messageObject === "ASS" && messageAbout === "001",
      then: () => Yup.string().required("Ce champ est obligatoire, veuillez préciser votre demande."),
    }),
    startDate: Yup.date().when(["messageObject", "messageAbout"], {
      is: (messageObject: string, messageAbout: string) => messageObject === "ASS" && messageAbout === "001",
      then: () => Yup.date().required("Ce champ est obligatoire, veuillez préciser votre demande."),
    }),
    endDate: Yup.date().when(["messageObject", "messageAbout"], {
      is: (messageObject: string, messageAbout: string) => messageObject === "ASS" && messageAbout === "001",
      then: () => Yup.date().required("Ce champ est obligatoire, veuillez préciser votre demande."),
    }),
  })

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      messageObject: "",
      idBien: "",
      messageAbout: "",
      message: "",
      requestId,
      file: [],
      commonPart: false,
      startDate: "",
      endDate: "",
      insurer: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm, setFieldError }) => {
      setFormsend(true)
      if (!values.idBien) {
        console.warn("Unexpected value for idBien.")
        dispatch<any>(toggleErrorMessageAction())
        setFormsend(false)
        return
      }

      if (values.messageObject === "ASS" && values.messageAbout === "001") {
        if (values.startDate && values.endDate) {
          // compare dates before send
          if (moment(values.startDate).locale("fr").isAfter(moment(values.endDate).locale("fr").toDate())) {
            toast.error(
              "Vérifiez votre saisie. Veillez à bien renseigner les champs dates de début et de fin et dans le bon ordre."
            )
            setFieldError("startDate", "Ce champ est incorrect.")
            setFieldError("endDate", "Ce champ est incorrect.")
            setFormsend(false)
            return
          }
        }
        if (!values.file) {
          toast.error("Envoi de fichier obligatoire. Veillez à bien renseigner tous les champs.")
          setFieldError("file", "Ce champ est obligatoire.")
          setFormsend(false)
          return
        }
      }
      // TODO: remove comment for file
      postRequest(
        values.messageAbout,
        values.idBien,
        values.messageObject,
        values.message,
        values.file,
        values.commonPart,
        values.startDate,
        values.endDate,
        values.insurer
      )
        .then(() => {
          setTimeout(() => {
            resetForm()
            dispatch<any>(toggleSuccessMessageAction())
            setFormsend(false)
            setfileList([])
          }, 500)
        })
        .catch((error) => {
          resetForm()
          setfileList([])
          setFormsend(false)
          if (error?.response) {
            setIsError(true)
            console.error(`Error status ${error.response.status}: ${error.response.statusText}`)
            toast.error(error.response.data.error)
          } else {
            console.error("We cant send new request.")
            dispatch<any>(toggleErrorMessageAction())
          }
        })
        .finally(() => {
          setCloseRequest(true)
        })
    },
  })

  const deleteFile = async (key: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement
    const oldFile = formik.values.file
    setIsDeleteFileLoading(true)

    try {
      await deleteFileRequest(`${element.dataset.filename}_${formik.values.requestId}`)
      setfileList(() => fileList.filter((item: any, index: number) => key !== index))
      oldFile.splice(key, 1)
      formik.setFieldValue("file", [...oldFile])
      fileRef.current.value = ""
      setIsDeleteFileLoading(false)
    } catch (error: any) {
      if (error?.response) {
        console.error(`Error status ${error.response.status}: ${error.response.statusText}`)
        toast.error(error.response.data.error)
      } else {
        console.error("We cant delete file.")
        dispatch<any>(toggleErrorMessageAction())
      }
      setIsDeleteFileLoading(false)
    }
  }

  const homeDetails = useHomeDetails(requestBiens)

  const homeOptions: Array<{ label: string; value: string }> = useMemo(
    () =>
      homeDetails
        .filter((data) => !data.isLoading && data)
        .map(({ data: home }) => ({
          label: `${home?.kindLabel} réf. #${home?.UGCode}`,
          value: home?.UGCode ?? "",
        })),
    [homeDetails]
  )

  useEffect(() => {
    if (
      undefined !== homeOptions[0] &&
      (formik.values.idBien === "" || !requestBiens.includes(formik.values.idBien))
    ) {
      formik.setFieldValue("idBien", homeOptions[0].value)
    }
  }, [homeOptions, requestQualifiers])

  const getObjectMessages = () => {
    const options: Array<{ label: any; value: any }> = []
    requestQualifiers?.forEach((qualifier) => {
      options.push({ label: qualifier.label, value: qualifier.code })
    })

    return options
  }

  const getAssuranceOptionsValues = () => {
    const options: Array<{ label: string; value: string }> = []
    assurances?.forEach((assurance) => {
      options.push({
        label: assurance,
        value: assurance,
      })
    })

    return options
  }

  const messageAbout = () => {
    const object = formik.values.messageObject
    if (!object) {
      return []
    }

    return requestQualifiers?.find((qualifier: Qualifiers) => qualifier.code === object)?.types
  }

  const aboutRef: any = useRef(new WeakMap())
  const commonPartRef: any = useRef(formik.values.commonPart)
  const startDateInput = useRef(null)
  const endDateInput = useRef(null)

  useEffect(() => {
    formik.setValues({ ...formik.values, idBien: "" })
  }, [])

  useEffect(() => {
    if (location.state) {
      const { state } = location
      const data: any = [state]

      if (data) {
        if (
          data[0]?.messageObject &&
          data[0]?.messageAbout &&
          data[0]?.messageObject === "ASS" &&
          data[0]?.messageAbout === "001"
        ) {
          formik.setValues({ ...formik.values, messageObject: "ASS", messageAbout: "001" }, false)
          setIsFileAllowed(true)
          setIsFileRequired(true)
        }
      }
    } else {
      if (formik.values.messageObject === "ASS" && formik.values.messageAbout === "001") {
        formik.setValues({ ...formik.values, messageObject: "", messageAbout: "" }, false)
        if (isFileRequired) {
          setIsFileRequired(false)
        }
      }
      if (requestBiens?.length === 1) {
        formik.setValues({
          ...formik.values,
          idBien: requestBiens[0].toString(),
        })
      }
    }
  }, [location])

  useEffect(() => {
    if (formik.values.messageObject === "ASS" && formik.values.messageAbout === "001") {
      if (!isFileRequired) {
        setIsFileRequired(true)
      }
    } else if (isFileRequired) {
      setIsFileRequired(false)
    }
  }, [formik.values])

  return (
    <div className="new-message_content">
      <section className="container-section-width--padded new-message_container">
        <form onSubmit={formik.handleSubmit} className="login_form">
          <div className="new-message_container_part">
            <label className="new-message_title" id="objetDemande">
              Quel est <strong>l&apos;objet</strong> de votre demande
              <span className="new-message_subtitle"> (Obligatoire)</span>
            </label>
            <Select
              className="new-message_select-object"
              id="messageObject"
              name="messageObject"
              options={getObjectMessages()}
              formik={formik}
              ariaLabelledby="objetDemande"
              ariaRequired
              ariaDescId="errorMessageObject"
              value={formik.values.messageObject}
            />
          </div>

          {formik.values.messageObject !== "" && (
            <>
              {formik.values.messageObject === "TEC" ? (
                <div className="new-message_container_part new-message_container_part_about">
                  <div className="new-message_title">
                    Est-ce que votre demande concerne la <strong>partie commune</strong>?
                    <span className="new-message_subtitle"> (Obligatoire)</span>
                  </div>
                  <fieldset className="new-message_option-about_container">
                    <legend className="sr-only">Précision de la demande concernant la partie commune</legend>
                    {["Oui", "Non"].map((about: string, i: number) => {
                      const transformAbout = about !== "Non"
                      return (
                        <div key={formik.values.messageObject + about}>
                          <input
                            ref={commonPartRef}
                            id={about + i}
                            name="commonPart"
                            type="radio"
                            value={about}
                            tabIndex={-1}
                            checked={transformAbout === formik.values.commonPart}
                            title="Précision de la demande"
                            onChange={() => {
                              formik.setValues(
                                {
                                  ...formik.values,
                                  commonPart: transformAbout,
                                },
                                false
                              )
                            }}
                            required
                          />

                          <label
                            tabIndex={0}
                            className="new-message_radio-about new-message_radio-about_checked"
                            onClick={() => {
                              commonPartRef.current = transformAbout
                            }}
                            htmlFor={about + i}
                            onKeyUp={(e: any) => {
                              const valueToSet = e.target.control.value !== "Non"

                              if (e.code === "Space") {
                                commonPartRef.current = valueToSet

                                e.target.control.checked = true
                                formik.setValues(
                                  {
                                    ...formik.values,
                                    commonPart: valueToSet,
                                  },
                                  false
                                )
                              }
                            }}
                          >
                            <p>{about}</p>
                          </label>
                        </div>
                      )
                    })}
                  </fieldset>
                </div>
              ) : null}

              <div className={`new-message_container_part ${requestBiens?.length <= 1 ? "hide" : ""}`}>
                {formik.values.messageObject !== "TEC" || !formik.values.commonPart ? (
                  <label className="new-message_title" id="objetIdBien">
                    Quel est <strong>le bien concerné</strong>?
                    <span className="new-message_subtitle"> (Obligatoire)</span>
                  </label>
                ) : (
                  <label className="new-message_title" id="objetIdBien">
                    Votre demande concerne <strong>quelle partie commune</strong>?
                    <span className="new-message_subtitle"> (Obligatoire)</span>
                  </label>
                )}
                <Select
                  className="new-message_select-object"
                  id="idBien"
                  name="idBien"
                  disabled={requestBiens?.length < 2}
                  options={homeOptions}
                  formik={formik}
                  ariaLabelledby="objetIdBien"
                  ariaRequired
                  ariaDescId="errorMessageIdBien"
                />
              </div>

              <div className="new-message_container_part new-message_container_part_about">
                <div className="new-message_title">
                  Votre demande <strong>concerne</strong>
                  <span className="new-message_subtitle"> (Obligatoire)</span>
                </div>
                <fieldset className="new-message_option-about_container">
                  <legend className="sr-only">Précision de la demande</legend>
                  {messageAbout()?.map((about: QualifiersType, i: number) => (
                    <div key={about.id}>
                      <input
                        ref={(el) => aboutRef.current.set(about, el)}
                        className={about.id}
                        id={`about${i}`}
                        name="messageAbout"
                        type="radio"
                        value={about.code}
                        tabIndex={-1}
                        checked={about.code === formik.values.messageAbout}
                        title="Précision de la demande"
                        onChange={(e) => {
                          formik.setValues(
                            {
                              ...formik.values,
                              messageAbout: e.target.value,
                            },
                            false
                          )
                          setIsFileAllowed(about.attachments)
                        }}
                        required
                      />

                      <label
                        tabIndex={0}
                        className="new-message_radio-about new-message_radio-about_checked"
                        onClick={() => aboutRef.current.get(about).click()}
                        htmlFor={`about${i}`}
                        onKeyUp={(e: any) => {
                          if (e.code === "Space") {
                            aboutRef.current.get(about).click()
                          }
                        }}
                      >
                        <p>{about.label}</p>
                      </label>
                    </div>
                  ))}
                </fieldset>
              </div>
              <div className="new-message_container_part">
                {formik.values.messageObject === "ASS" && formik.values.messageAbout === "001" ? (
                  <>
                    <div className="new-message_container_part">
                      <div className="new-message_title" id="insurancenameLabelledBy">
                        Nom de l&apos;assurance
                        <span className="new-message_subtitle"> (Obligatoire)</span>
                      </div>
                      <fieldset className="new-message_option-about_container">
                        <legend className="sr-only">
                          Précision de la demande concernant les attestations d&apos;assurance (selectionner une
                          assurance).
                        </legend>
                        <div className="assur-container">
                          <Select
                            className="new-message_select-object assur-select"
                            id="insurer"
                            name="insurer"
                            options={getAssuranceOptionsValues()}
                            formik={formik}
                            placeholder="Selectionner une assurance"
                            ariaLabelledby="insurancenameLabelledBy"
                            ariaRequired
                            ariaDescId="errorInsurerValue"
                          />
                        </div>
                      </fieldset>
                    </div>

                    <div className="new-message_container_part">
                      <div className="new-message_title" id="startdateLabelledBy">
                        Date de début de l&apos;assurance
                        <span className="new-message_subtitle"> (Obligatoire)</span>
                      </div>
                      <fieldset className="new-message_option-about_container">
                        <legend className="sr-only">
                          Précision de la demande concernant les attestations d&apos;assurance (date de début).
                        </legend>
                        <div className="assur-container">
                          <Input
                            className="new-message_select-object startdate-input"
                            id="insurance_startDate"
                            name="startDate"
                            type="date"
                            icon=""
                            title="Date de début de prise en charge de l'assurance (voir attestation d'assurance)."
                            ariaLabelledby="startdateLabelledBy"
                            innerRef={startDateInput}
                            formik={formik}
                            onChange={(e) => {
                              formik.setValues(
                                {
                                  ...formik.values,
                                  startDate: e.target.value,
                                },
                                false
                              )
                            }}
                          />
                        </div>
                      </fieldset>
                    </div>

                    <div className="new-message_container_part">
                      <div className="new-message_title" id="enddateLabelledBy">
                        Date de fin de l&apos;assurance
                        <span className="new-message_subtitle"> (Obligatoire)</span>
                      </div>
                      <fieldset className="new-message_option-about_container">
                        <legend className="sr-only">
                          Précision de la demande concernant les attestations d&apos;assurance (date de fin).
                        </legend>
                        <div className="assur-container">
                          <Input
                            className="new-message_select-object enddate-input"
                            id="insurance_endDate"
                            name="endDate"
                            type="date"
                            icon=""
                            // value=""
                            title="Date de début de prise en charge de l'assurance (voir attestation d'assurance)."
                            ariaLabelledby="enddateLabelledBy"
                            innerRef={endDateInput}
                            formik={formik}
                            onChange={(e) => {
                              formik.setValues(
                                {
                                  ...formik.values,
                                  endDate: e.target.value,
                                },
                                false
                              )
                            }}
                          />
                        </div>
                      </fieldset>
                    </div>
                  </>
                ) : null}
              </div>
            </>
          )}
          <div className="new-message_container_part">
            <InputTextMessenger
              formik={formik}
              fileList={fileList}
              deleteFile={deleteFile}
              setfileList={setfileList}
              isFileAllowed={isFileAllowed}
              isFileRequired={isFileRequired}
              formsend={formsend}
              isFileLoading={isFileLoading}
              setIsFileLoading={setIsFileLoading}
              isDeleteFileLoading={isDeleteFileLoading}
              isError={isError}
              fileRef={fileRef}
            />
          </div>
        </form>
      </section>
    </div>
  )
}

export default NewRequestForm

import { useHistory, useLocation } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { useAllHomeDetails } from "hooks/myHome"
import InsuranceStatus from "components/InsuranceStatus"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import Footer from "../../components/Footer"
import NewsDashboard from "../../components/NewsDashboard"
import Rent from "../../components/Rent"
import AuthLayout from "../../layouts/AuthLayout"
import DocumentsList from "../../components/DocumentsList"
import Button from "../../components/Button"
import "./Dashboard.scss"
import "../../components/DocumentsDashboard.scss"
import { useRent } from "../../hooks/rent"
import { useDocuments } from "../../hooks/documents"
import { useNews } from "../../hooks/news"

export default function Dashboard() {
  const router = useHistory<{ message: string; email?: string }>()
  const { pathname } = useLocation()
  const { data: newsData } = useNews()
  const { data: billing, isLoading: billingIsLoading } = useRent()
  const { data: documents } = useDocuments(null, null)
  const { data: allHomeDetails, isLoading: isHomeDetailsLoading } = useAllHomeDetails()

  return (
    <>
      <Helmet>
        <title>Tableau de bord - Espace Locataire - Vilogia</title>
        <meta
          name="description"
          content="Le tableau de bord permet d'accèder et de visualiser un ensemble d'informations, les plus significatives de votre espace locataire."
        />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <AuthLayout>
        <div className="dashboard">
          <div className="container-width--padded">
            <h1 className="sr-only">Tableau de bord</h1>
            <div className="dashboard__row">
              <Rent billing={billing} isLoading={billingIsLoading} />
              <div className="documents">
                <h2 className="documents-dashboard__title">Mon assurance</h2>
                {isHomeDetailsLoading ? (
                  <Skeleton count={1} height={40} borderRadius={15} />
                ) : (
                  allHomeDetails &&
                  !isHomeDetailsLoading &&
                  allHomeDetails.map((result) => (
                    <div key={result?.UGCode} className="accomodation-list_element">
                      <InsuranceStatus
                        homeDetails={result}
                        display="all"
                        origin="dashboard"
                        isLoading={isHomeDetailsLoading}
                      />
                    </div>
                  ))
                )}
                <h2 className="documents-dashboard__title">Mes documents</h2>
                {undefined !== documents && (
                  <>
                    <DocumentsList documents={documents.slice(0, 3)} />
                    {documents.length > 0 && (
                      <Button
                        className="small documents__button"
                        onClick={() => {
                          router.push("/documents")
                        }}
                        buttonTitle="Lien vers la page de documents"
                      >
                        Voir tous mes documents
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
            <NewsDashboard newsProps={newsData?.pages[0]} />
          </div>
        </div>
        <Footer />
      </AuthLayout>
    </>
  )
}
